<!--
 * @Author: 矢车
 * @Date: 2020-09-13 21:02:25
 * @LastEditors: 矢车
 * @LastEditTime: 2020-11-03 10:25:09
 * @Description: 直播管理 - 专场报名 - 查看详情
-->

<template>
  <div id="applyDetail">
    <a-card class="mb-20" size="small">
      <div slot="title">
        <div class="app-d-list">
          <div class="tl">
            <div style="display: block;">
              <div class="mr-20">
                审核状态：
                <a-tag color="purple">{{ info.special_apply_examine_statusFormat }}</a-tag>
              </div>
              <div class="mt10" v-if="info.special_apply_examine_statusFormat==='未通过'">
                未通过原因：{{info.examine_remark || '--'}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ul class="sample-info-b">
          <li>
            <dl class="apply-user">
              <dt class="ptb">申请人</dt>
              <dd class="apply-tab">
                <div class="au-mobile">注册手机：{{ info.mobile }}</div>
                <div class="au-channel">
                  渠道：{{ info.channel == 2 ? '淘宝' : info.channel == 1 ? '抖音' : '--' }}
                </div>
                <div class="au-nickname">主播昵称：{{ info.nick }}</div>
                <div class="au-lv">主播等级：{{ info.user_levelFormat }}</div>
              </dd>
            </dl>
          </li>
        </ul>
      </div>
    </a-card>

    <a-card title="专场信息" class="mb-20" size="small">
      <table class="n-table">
        <thead>
          <tr>
            <th width="200">专场名称</th>
            <th width="100">专场ID</th>
            <th width="100">专场类型</th>
            <th width="100">专场渠道</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ info.special__title }}</td>
            <td>{{ info.special_id }}</td>
            <td>{{ info.special__special_type == 1 ? '品牌专场' : '主题专场' }}</td>
            <td>{{ info.special__goods_channel == 1 ? '淘宝联盟' : '抖音小店' }}</td>
          </tr>
        </tbody>
      </table>
    </a-card>
  </div>
</template>
<script>
import { specialDetail } from '@/service/sample';
import { formatKuranMethods, auditStatus } from '@/components/admin/operate/menuFormat';

export default {
  name: 'SpecialDetail',
  props: {
    id: {
      default: '',
    },
  },
  created() {
    this.getData();
  },
  data() {
    return {
      info: {},
    };
  },
  methods: {
    formatKuranMethods,
    auditStatus,

    // 获取数据
    async getData() {
      const { err, res } = await specialDetail(this.id);
      if (!err) {
        if (res.success) {
          this.info = res.data;
          this.info.special_apply_examine_statusFormat = this.auditStatus(
            res.data.special_apply_examine_status,
          );
          this.info.user_levelFormat = this.formatKuranMethods(res.data.user_level);
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.sample-info-b {
  margin: 0;
  padding: 0 0;
  list-style: none;
  li {
    margin-bottom: 5px;
  }
}
.mb-20 {
  margin-bottom: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.n-table {
  width: 100%;
  th,
  td {
    padding: 5px;
    border: 1px solid #eee;
  }
  th {
    background: #f5f5f5;
  }
}
.app-d-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tl {
    display: flex;
    flex-wrap: wrap;
  }
  .tr {
  }
}
.apply-user {
  margin-bottom: 10px;
  border: 1px solid #eee;
  dt {
    margin-bottom: 5px;
    border-bottom: 1px solid #eee;
    background: #f5f5f5;
    padding-left: 5px;
  }
  dd {
    padding: 5px;
    display: flex;
    .au-user {
      width: 100px;
    }
    .au-addr {
      width: 480px;
    }
  }
}
.express-list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  .el-name {
    width: 220px;
  }
  .el-no {
    width: 250px;
  }
  .el-time {
    width: 220px;
  }
}
.return-info {
  font-weight: normal;
  display: flex;
  margin-bottom: 20px;
  div {
    margin-right: 30px;
  }
}
.cancel-reason {
  display: flex;
  .value {
    width: 700px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.apply-tab {
  display: flex;
  justify-content: space-around;
}
.ptb {
  padding: 8px 0;
}
th,
td {
  padding: 8px !important;
}
</style>
