<!--
 * @Author: 矢车
 * @Date: 2020-09-13 14:50:58
 * @LastEditors: 矢车
 * @LastEditTime: 2020-09-18 16:43:18
 * @Description: 直播管理 -- 专场报名
-->

<template>
  <div id="sampleApply">
    <SampleSpecialSearchBar
      @callback="sampleSpecialSearchCallback"
      :searchParams="searchParams"
      :loading="listLoading"
    />
    <a-table
      :scroll="{ x: 1200, y: tableScrollY }"
      :rowKey="record => record.id"
      :columns="sampleSpecialColumns"
      :dataSource="list"
      :pagination="pagination"
      :loading="listLoading"
      :locale="{ filterConfirm: '暂无数据' }"
      @change="handleTableChange"
      style="margin-top: 20px"
    >
      <template slot="nick" slot-scope="record">
        <span style="-webkit-line-break: inherit;white-space:nowrap;text-overflow:ellipsis; overflow:hidden;display: inline-block;width: 100px;">{{record.nick}}</span>
      </template>
      <template slot="deadlineTime" slot-scope="record">
        <span>{{
          record.apply_deadline_time ? record.apply_deadline_time.substr(0, 16) : ''
        }}</span>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button size="small" class="mr10" @click="checkDetail(record)">查看</a-button>
        <a-button
          v-auth="'samples_manage_special_apply_audit'"
          type="primary"
          size="small"
          v-if="record.special_apply_examine_status == 1"
          @click="
            modalAudit.isShow = true;
            id = record.id;
          "
          >审核</a-button
        >
      </template>
    </a-table>
    <a-modal
      v-model="modalAudit.isShow"
      title="专场报名审核"
      @ok="modalConfirm"
      @cancel="onModalClose"
      okText="确认提交"
      width="320px"
    >
      <span>审核结果：</span>
      <a-select v-model="auditResult" style="width: 200px">
        <a-select-option value="">请选择</a-select-option>
        <a-select-option value="2">通过</a-select-option>
        <a-select-option value="3">未通过</a-select-option>
      </a-select>
      <a-textarea
        v-model="remark"
        v-if="modalAudit.isTextareaShow"
        :maxLength="50"
        placeholder="请填写拒绝原因,会直接展示给用户,注意文案规范"
        :auto-size="{ minRows: 3, maxRows: 6 }"
        style="margin-top: 20px; width: 270px"
      />
    </a-modal>
    <!-- 查看 -->
    <template>
      <a-drawer
        :destroyOnClose="true"
        title="查看详情"
        :width="720"
        :visible="specialDetaiDrawer.isShow"
        :body-style="{ paddingBottom: '80px' }"
        @close="onDetailClose"
      >
        <SpecialDetail @closeDrawers="closeDrawer" :id="specialDetaiDrawer.id"></SpecialDetail>
      </a-drawer>
    </template>
  </div>
</template>

<script>
import { specialApply } from '@/service/operate';
import { specialAudit } from '@/service/sample';
import SampleSpecialSearchBar from '@/components/admin/sample/sample-special-search-bar';
import SpecialDetail from '@/components/admin/sample/special-detail';

// 寄样申请列表
const sampleSpecialColumns = [
  {
    title: '主播昵称',
    scopedSlots: { customRender: 'nick' },
    align: 'center',
    fixed: 'left',
    width: 120,
  },
  {
    title: '渠道',
    fixed: 'left',
    dataIndex: 'channelFormat',
    align: 'center',
    width: 100,
  },
  {
    title: '主播等级',
    dataIndex: 'user_levelFormat',
    align: 'center',
    width: 100,
  },
  {
    title: '注册手机',
    dataIndex: 'mobile',
    align: 'center',
    width: 120,
  },
  {
    title: '专场名称',
    dataIndex: 'special__title',
    align: 'center',
    width: 180,
  },
  {
    title: '审核状态',
    align: 'center',
    dataIndex: 'special_apply_examine_statusFormat',
    width: 80,
  },
  {
    title: '直播排期',
    dataIndex: 'start_time',
    align: 'center',
    width: 160,
    scopedSlots: { customRender: 'expressStatus' },
  },
  {
    title: '报名时间',
    dataIndex: 'create_time',
    align: 'center',
    width: 160,
    scopedSlots: { customRender: 'start_time' },
  },
  {
    title: '操作',
    align: 'center',
    fixed: 'right',
    width: 140,
    scopedSlots: { customRender: 'operation' },
  },
];

export default {
  data() {
    return {
      tableScrollY: 600,
      form: this.$form.createForm(this),
      sampleSpecialColumns,
      list: [],
      id: '',
      listLoading: true,
      auditResult: '',
      remark: '',
      modalAudit: {
        isShow: false,
        isTextareaShow: false,
      },
      specialDetaiDrawer: {
        isShow: false,
        id: '',
      },
      searchParams: {
        type: '1',
        special__title: '', //  专场名称
        special_id: '', // 专场id
        user_mobile: '', // 注册手机
        nickname: '', // 主播昵称
        special__goods_channel: '',
        special__custom_cat: '',
        special__special_type: '',
        special_apply_examine_status: '',
        date_type: '1',
        start_date: '',
        end_date: '',
      },
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize,
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) => (this.pagination.pageSize = pageSize),
      },
    };
  },
  watch: {
    auditResult(newVal) {
      if (newVal == 3) {
        this.modalAudit.isTextareaShow = true;
      } else {
        this.modalAudit.isTextareaShow = false;
      }
    },
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 355;
  },
  components: {
    SampleSpecialSearchBar,
    SpecialDetail,
  },
  mounted() {
    this.getTopicList();
  },
  methods: {
    //  获取列表
    async getTopicList(search) {
      let data = Object.assign({}, search);
      const tempStr = data.nickname;
      data.nickname = '';
      switch (data.type) {
        case '1':
          data.special__title = tempStr;
          break;
        case '2':
          data.special_id = tempStr;
          break;
        case '3':
          data.user_mobile = tempStr;
          break;
        case '4':
          data.nickname = tempStr;
          break;
      }
      delete data.type; //  删除type
      (data.page = this.pagination.current), (data.page_size = this.pagination.pageSize);
      this.listLoading = true;
      const { err, res } = await specialApply(data);
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          this.list = res.data.results.map(item => {
            return {
              ...item,
              channelFormat: item.special__goods_channel == 1 ? '淘宝联盟' : '抖音小店',
              user_levelFormat: this.levelFormatFunc(item.user_level),
              special_apply_examine_statusFormat: this.statusFormartFunc(
                item.special_apply_examine_status,
              ),
            };
          });
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        }
      }
    },

    // 点击查看详情
    checkDetail(record) {
      this.specialDetaiDrawer.id = record.id;
      this.specialDetaiDrawer.isShow = true;
    },

    // 跳页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getTopicList(this.searchParams);
    },
    // 搜索查询按钮
    sampleSpecialSearchCallback(res) {
      this.pagination.current = 1;
      this.searchParams = Object.assign({}, res);
      this.getTopicList(this.searchParams);
    },

    // 审核状态点击确定
    async modalConfirm() {
      if (!this.auditResult) {
        this.$message.warning('请选择审核结果');
        return;
      }
      const { err, res } = await specialAudit(this.id, {
        special_apply_examine_status: this.auditResult,
        examine_remark: this.auditResult == 2 ? '' : this.remark,
      });
      if (!err) {
        if (res.success) {
          this.$message.success('提交成功');
          this.modalAudit.isShow = false;
          // 清空modal上的数据
          this.auditResult = '';
          this.remark = '';
          this.getTopicList(this.searchParams);
        }
      }
    },

    onModalClose() {
      this.auditResult = '';
      this.remark = '';
    },

    onDetailClose() {
      this.specialDetaiDrawer.isShow = false;
    },

    //  关闭所有drawer
    closeDrawer() {
      this.specialDetaiDrawer.isShow = false;

      //  刷新列表数据
      this.getTopicList();
    },

    // 等级格式化
    levelFormatFunc(val) {
      let temp;
      switch (val) {
        case 1:
          temp = 'LV1';
          break;
        case 2:
          temp = 'LV2';
          break;
        case 3:
          temp = 'LV3';
          break;
        case 4:
          temp = 'LV4';
          break;
        case 5:
          temp = 'LV5';
          break;
      }
      return temp;
    },

    // 审核状态格式化
    statusFormartFunc(val) {
      let temp;
      switch (val) {
        case 1:
          temp = '待审核';
          break;
        case 2:
          temp = '已通过';
          break;
        case 3:
          temp = '未通过';
          break;
        case 4:
          temp = '已取消';
          break;
      }
      return temp;
    },
  },
};
</script>
<style lang="less" scope>
  #sampleApply{
    /deep/ .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
    .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
  }
.option-btns {
  margin-bottom: 20px;
  margin-top: 10px;
  .option-btn {
    margin-right: 10px;
  }
}
.mr-20 {
  margin-right: 20px;
}
</style>
