<!--
 * @Author: 矢车
 * @Date: 2020-09-13 14:57:40
 * @LastEditors: 矢车
 * @LastEditTime: 2020-09-16 13:44:52
 * @Description: 直播管理 -- 专场报名
-->

<template>
  <a-form class="filter-condition-form" layout="inline" :form="form" @submit="handleFormSubmit">
    <a-form-item>
      <a-select style="width: 120px" placeholder="专场名称" v-model="searchParams.type" allowClear>
      <!--  <a-select-option  value="1">专场名称</a-select-option>
        <a-select-option value="2">专场ID</a-select-option>
        <a-select-option value="3">注册手机</a-select-option>
        <a-select-option value="4">主播昵称</a-select-option>-->
        <a-select-option
          v-for="item in optionarr"
          :value="item.id"
          :key="item.id"
        >{{ item.value }}</a-select-option
        >
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-input v-model="searchParams.nickname" style="width: 200px"   :placeholder="`请输入${optionarr[searchParams.type-1].value}`" allowClear />
    </a-form-item>
    <a-form-item>
      <a-select
        style="width: 120px"
        placeholder="商品渠道"
        v-model="searchParams.special__goods_channel"
        allowClear
      >
        <a-select-option value="">商品渠道</a-select-option>
<!--        <a-select-option value="1">淘宝联盟</a-select-option>-->
        <a-select-option value="2">抖音小店</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-select
        v-model="searchParams.special__custom_cat"
        placeholder="专场类目"
        style="width: 120px"
        allowClear
      >
        <a-select-option value="">专场类目</a-select-option>
        <a-select-option value="8">女装</a-select-option>
        <a-select-option value="1">美妆个护</a-select-option>
        <a-select-option value="4">美食</a-select-option>
        <a-select-option value="3">居家百货</a-select-option>
        <a-select-option value="10">母婴</a-select-option>
        <a-select-option value="9">男装</a-select-option>
        <a-select-option value="5">鞋品箱包</a-select-option>
        <a-select-option value="7">珠宝配饰</a-select-option>
        <a-select-option value="12">数码家电</a-select-option>
        <a-select-option value="11">户外运动</a-select-option>
        <a-select-option value="6">文娱车品</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-select
        style="width: 120px"
        placeholder="专场类型"
        v-model="searchParams.special__special_type"
        allowClear
      >
        <a-select-option value="">专场类型</a-select-option>
        <a-select-option value="1">品牌专场</a-select-option>
        <a-select-option value="2">主题专场</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-select
        style="width: 120px"
        placeholder="审核状态"
        v-model="searchParams.special_apply_examine_status"
        allowClear
      >
        <a-select-option value="">审核状态</a-select-option>
        <a-select-option value="1">待审核</a-select-option>
        <a-select-option value="2">已通过</a-select-option>
        <a-select-option value="3">未通过</a-select-option>
        <a-select-option value="4">已取消</a-select-option>
      </a-select>
    </a-form-item>
    <br />
    <a-form-item>
      <a-select
        style="width: 120px"
        placeholder="直播排期"
        v-model="searchParams.date_type"
        allowClear
      >
        <a-select-option value="1">排期时间</a-select-option>
        <a-select-option value="2">报名时间</a-select-option>
        <a-select-option value="3">审核时间</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-range-picker @change="onChangeDate" />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" :loading="loading">查询</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
export default {
  name: 'SampleSpecialSearchBar',
  props: {
    searchParams: {
      type: Object,
      default: function () {
        return {};
      },
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      optionarr:[{id:"1",value:"专场名称"},
          {id:"2",value:"专场ID"},
          {id:"3",value:"注册手机"},
        {id:"4",value:"主播昵称"},]
    };
  },
  methods: {
    handleFormSubmit(e) {
      e.preventDefault();
      this.form.validateFields(err => {
        if (!err) {
          this.$emit('callback', this.searchParams);
        }
      });
    },
    // 时间段选择
    onChangeDate(date, dateString) {
      this.searchParams.start_date = dateString[0];
      this.searchParams.end_date = dateString[1];
    },
  },
};
</script>
<style lang="less" scope></style>
